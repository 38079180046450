<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="分组" prop="uid">
        <el-input
          v-model="searchForm.group"
          maxlength="8"
          clearable
          placeholder="分组编号， 比如1"
        ></el-input>
      </el-form-item>
      <el-form-item label="门派ID" prop="sect_id">
        <el-input
          v-model="searchForm.sect_id"
          maxlength="6"
          clearable
          placeholder="门派ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="门派名称" prop="sect_name">
        <el-input
          v-model="searchForm.sect_name"
          clearable
          placeholder="门派名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage_group`"
            @click="changeGroup(scope.row)"
            style="margin-left: 10px"
            >替换门派</perButton
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <div v-if="groupVisible">
      <change_group
        :match_id="this.match_id"
        :stage="stage"
        :from_data="groupData"
        @changed="onGroupChanged"
        @closed="groupVisible = false"
      ></change_group>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import change_group from "./change_group";
export default {
  name: "s1_group",
  components: {
    perButton,
    change_group,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      stage: "",
      searchForm: {
        group: "",
        sect_id: "",
        sect_name: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "group",
          label: "分组",
          width: 300,
        },
        {
          prop: "sect_id",
          label: "门派ID",
          width: 300,
        },
        {
          prop: "sect_name",
          label: "门派名称",
          width: 200,
        },
      ],
      groupVisible: false,
      groupData: {
        from_id: "",
        from_group: "",
        from_sect_id: "",
        from_sect_name: "",
        to_id: "",
      },
      match: false,
    };
  },
  methods: {
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    changeGroup(row) {
      this.groupData = {
        _id: row._id,
        group: row.group,
        sect_id: row.sect_id,
        sect_name: row.sect_name,
      };

      this.groupVisible = true;
    },
    // 替换门派弹框确定按钮
    onGroupChanged(data) {
      data.forEach((r) => {
        let item = this.dataTable.find((r) => r._id == r._id);
        if (item) {
          item.group = r.group;
          item.sect_id = r.sect_id;
          item.sect_name = r.sect_name;
          item.sect_level = r.sect_level;
        }
      });
      console.log(111);
      this.handleQuery();
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.match.stage_group_list({
        ...this.searchForm,
        match_id: this.match_id,
        stage: this.stage,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query.match_id;
    this.stage = this.$route.query.stage;
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
